import React, { useEffect, useState } from 'react'
import capitalize from 'lodash/capitalize'
import compact from 'lodash/compact'
import flattenDeep from 'lodash/flattenDeep'
import moment from 'moment'
import sortBy from 'lodash/sortBy'
import { Badge, Button, CustomInput, Modal, ModalBody, Spinner } from 'reactstrap'
import { navigate } from 'gatsby'
import { useDispatch, useSelector } from 'react-redux'

import AiIndications, { IndicationsView } from './Indications'
import CONFIG from '../../config'
import ImagesBlock, { InitialAssesmentImage } from './ImagesBlock'
import { OrganizationEvent } from '../../lib/organizationEventTypes'
import { casesSelector, CasesState, fetchCaseAction } from '../../hasura/slices/cases'
import { insertOrganizationEventAction, insertOrganizationEventForUserAction } from '../../hasura/slices/organizations'
import { predictionsSelector, PredictionsState } from '../../hasura/slices/predictions'
import { slackMessageAction, usersSelector, UsersState } from '../../hasura/slices/users'
import { Conditions_conditions } from '../../hasura/graphQlQueries/types/Conditions'

import {
  emojiFor,
  isAdmin,
  speciesFor,
  hasWindow,
  isDemoOrg,
  commaSeparatedString,
  ohifStudyUrl,
  trackHotjarEvent,
} from '../../lib/helpers'

import {
  conditionsForSpecies,
  getHeatmapImages,
  getPossibleConditions,
  getRisk,
  heatmapKeyFor,
  negativePredictionForCondition,
} from '../../lib/aiHelpers'

import {
  consultationsSelector,
  ConsultationsState,
  downloadConsultationPdfAction,
  fetchConsultationPdfAction,
  fetchImagePermutationsAction,
  fetchImagesAction,
} from '../../hasura/slices/consultations'

// @ts-ignore
import { get_predictions_for_image } from '../../lib/ml_rules_engine.js'
// @ts-ignore
import closeIcon from '../../lib/images/close-gray.svg'
// @ts-ignore
import aiConfig from '../../../aiConfig.json'

const mediumZoom = hasWindow ? require('medium-zoom') : undefined

interface Props {
  handleClose: (didEdit: boolean) => void
  id: number
}

export default function AiAssessmentModal(props: Props) {
  const dispatch = useDispatch()

  const { accessToken, user, role }: UsersState = useSelector(usersSelector)
  const { conditions }: ConsultationsState = useSelector(consultationsSelector)
  const { radimalCase }: CasesState = useSelector(casesSelector)
  const { isCreatingPdf }: PredictionsState = useSelector(predictionsSelector)

  const [didEdit, setDidEdit] = useState(false)
  const [zoomOpen, setZoomOpen] = useState(false)
  const [zoomCondition, setZoomCondition] = useState<Conditions_conditions | undefined>()
  const [displayHeatmap, setDisplayHeatmap] = useState(!CONFIG.IS_DEVELOPMENT)
  const [heatmapImagesExist, setHeatmapImagesExist] = useState(true)

  const [zoom, setZoom] = useState(
    hasWindow
      ? mediumZoom.default({
          margin: 20,
          background: 'rgba(0, 0, 0, 0)',
          container: '#zoom-container',
        })
      : undefined
  )

  zoom.on('close', () => {
    setZoomOpen(false)
    setDisplayHeatmap(true)
  })

  useEffect(() => {
    if (!radimalCase) return

    zoom.on('open', (e: any) => {
      setZoomCondition(conditions.find((c) => c.ml_name === e.target.alt))
      setZoomOpen(true)
      dispatch(insertOrganizationEventForUserAction(accessToken, OrganizationEvent.ZoomedImage, user?.organization.id))
    })
    const heatmapImagesExist = moment(radimalCase.created_at).isAfter(moment('2023-01-04'))
    setHeatmapImagesExist(heatmapImagesExist)
    if (!heatmapImagesExist) setDisplayHeatmap(false)
    setZoom(zoom)
  }, [radimalCase])

  useEffect(() => {
    if (radimalCase?.id !== props.id) return

    const heatmapImages = getHeatmapImages(radimalCase)
    const heatmapImageKeys = heatmapImages.map((a: any) => a.heatmap)
    const croppedImageKeys = heatmapImages.filter((a) => a.label).map((a) => [a.label, a.cropped])
    if (heatmapImageKeys.length) dispatch(fetchImagesAction(heatmapImageKeys))
    if (croppedImageKeys.length) dispatch(fetchImagePermutationsAction(croppedImageKeys))
    if (isAdmin(role)) return

    const msg = `${user?.organization.display_name} opened Initial Assessment report for ${radimalCase.patient.display_name}.`
    dispatch(slackMessageAction('ai-report', msg))
    const event = {
      organization_id: user?.organization.id,
      organization_event_type_id: OrganizationEvent.ViewedAIReport,
      case_id: radimalCase.id,
    }
    dispatch(insertOrganizationEventAction(accessToken, event))
    trackHotjarEvent('opened_initial_assessment')
  }, [radimalCase])

  useEffect(() => {
    if (accessToken) fetchCase()
  }, [accessToken])

  const fetchCase = () => dispatch(fetchCaseAction(accessToken, props.id, false))

  const downloadPdf = () => dispatch(downloadConsultationPdfAction(radimalCase?.s3_url!))

  const viewPdf = () => {
    dispatch(
      fetchConsultationPdfAction(
        radimalCase?.s3_url!,
        patient,
        ohifStudyUrl(radimalCase?.dicom_server_study_instance_uid, radimalCase?.dicom_source),
        true
      )
    )
    if (isAdmin(role) || isDemoOrg(user)) return

    const msg = `${user?.organization.display_name} opened AI PDF report for ${radimalCase?.patient.display_name}.`
    dispatch(slackMessageAction('ai-report', msg))
  }

  if (!radimalCase) return null

  const { patient, medical_images } = radimalCase
  const possibleConditions = getPossibleConditions(radimalCase, conditions).filter(
    (p) => !negativePredictionForCondition(p.id, radimalCase)
  )
  const risk = getRisk(radimalCase, conditions)
  const imageUrls = medical_images.map((m) => m.aws_s3_url)
  const checkedConditions = conditionsForSpecies(radimalCase.patient.species || 'dog', conditions)

  const unhealthyImageBlocks = sortBy(
    compact(
      possibleConditions.map((p, idx) => {
        const relevantImages: InitialAssesmentImage[] = sortBy(
          compact(
            medical_images.map((m) => {
              const prediction = get_predictions_for_image(aiConfig, m, radimalCase.patient.species).find(
                (p2: any) => p2.prediction.ml_name === p.ml_name
              )
              const view = prediction?.permutation ? capitalize(prediction?.permutation.label) : prediction?.image?.view?.display_name
              if (!p.ml_name || !prediction || !view) return

              return {
                heatmapUrl: heatmapKeyFor(m, prediction.prediction),
                name: p.ml_name,
                probability: prediction.prediction.probability,
                url: prediction?.permutation?.aws_s3_url || m.aws_s3_url,
                view,
                index: imageUrls.indexOf(m.aws_s3_url) + 1,
                imageId: m.id,
              }
            })
          ),
          (m) => -m.probability
        )

        const negativePrediction = negativePredictionForCondition(p.id, radimalCase)
        if (negativePrediction) return null

        return [
          <ImagesBlock
            description={p.symptoms_description_short}
            displayHeatmap={displayHeatmap}
            images={relevantImages}
            key={idx}
            title={p.display_name}
            zoom={zoom}
            highlight={false}
          />,
          p.display_name,
          relevantImages.map((r) => r.url),
          relevantImages.map((r) => r.imageId),
        ]
      })
    ),
    (p) => -p[1]
  )

  // @ts-ignore
  const unhealthyImageIds = flattenDeep(unhealthyImageBlocks.map((b) => b[3]))

  const healthyImages = radimalCase.medical_images
    .filter((m) => !unhealthyImageIds.includes(m.id))
    .map((m) => ({
      name: 'Healthy',
      probability: 1,
      url: m.aws_s3_url,
      view: m.view?.display_name || null,
      index: imageUrls.indexOf(m.aws_s3_url) + 1,
      imageId: m.id,
    }))

  const healthyImageBlock = healthyImages.length ? (
    <ImagesBlock key="healthy" title="Appears Normal" healthy displayHeatmap={displayHeatmap} zoom={zoom} images={healthyImages} />
  ) : null

  return (
    <div>
      <div className={`${zoomOpen ? 'd-flex' : 'd-none'} z-max-2 position-fixed vw-100 vh-100 left-0 top-0`}>
        <div onClick={() => zoom.close()} style={{ flex: 1, background: 'rgba(0, 0, 0, 0.8)' }} id="zoom-container"></div>

        <div className="bg--secondary2 h-100 py-5 px-3" style={{ width: '300px', backgroundColor: 'white' }}>
          <h1 className="text-xl text--gray8 bold">{zoomCondition?.display_name || 'Appears Normal'}</h1>
          <p className="text-m text--gray8">{zoomCondition?.symptoms_description_short}</p>

          {heatmapImagesExist && possibleConditions.length > 0 && zoomCondition && (
            <div className="d-flex align-items-center">
              <CustomInput
                id="healthy"
                checked={displayHeatmap}
                className="m-0 custom-switch-light"
                role="button"
                type="switch"
                onChange={() => {
                  const zoomedImage = document.getElementsByClassName('medium-zoom-image--opened')[0]
                  if (!zoomedImage) return

                  const originalSrc = zoomedImage.getAttribute('original-src')
                  // @ts-ignore
                  zoomedImage.setAttribute('original-src', zoomedImage.src)
                  // @ts-ignore
                  zoomedImage.src = originalSrc
                  setDisplayHeatmap(!displayHeatmap)
                }}
              />

              <p className="text-m mb-0">
                Highlighting is <span className="semibold">{displayHeatmap ? 'on' : 'off'}</span>
              </p>
            </div>
          )}
        </div>
      </div>

      <Modal
        className="modal-fullscreen"
        contentClassName="modal-fullscreen-content"
        fade={false}
        isOpen
        size="full"
        toggle={() => props.handleClose(didEdit)}
        zIndex={2147483002}
      >
        <ModalBody style={{ minHeight: '85vh' }} className="pt-4 px-4 pb-0 w-100 position-relative m-auto">
          <div className="d-flex justify-content-between align-items-start">
            <div style={{ width: 'calc(100vw - 40px)' }}>
              <div className="d-flex align-items-center">
                <p className="text-xxl mb-0 mr-4">
                  <span className="mr-1">{emojiFor(speciesFor(patient.species))}</span>

                  <span className="semibold">{patient.display_name}</span>

                  <span className="mx-2 text--gray8">|</span>

                  <span className="text--gray7">Initial Assessment</span>
                </p>

                <div className="d-flex align-items-end">
                  {radimalCase.s3_url && (
                    <div className="d-flex gap-10px">
                      <Button onClick={downloadPdf} className="min-width-100px" outline color="dark">
                        Download PDF
                      </Button>

                      <Button disabled={isCreatingPdf} onClick={viewPdf} className="min-width-100px" outline color="dark">
                        View PDF
                      </Button>
                    </div>
                  )}

                  {isCreatingPdf && (
                    <div className="ml-2 d-flex align-items-center">
                      <Spinner size="sm" color="gray" />
                      <p className="text-s text--gray6 ml-1 mb-0">Regenerating...</p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <img onClick={() => props.handleClose(didEdit)} className="icon-l pointer" src={closeIcon} />
          </div>

          <div style={{ marginLeft: '45px' }} className="d-flex gap-10px align-items-start max-width-600px">
            <Badge className={`transition-m text-m min-width-150px ${risk.toLowerCase()}-risk`}>{risk} Risk</Badge>

            <p className="mb-1 text-s">
              Patient images were analyzed{possibleConditions.length ? '' : ` and appear healthy`} for{' '}
              <span className="bold">{checkedConditions.length}</span> conditions.
              {possibleConditions.length
                ? ` Risk based on the
              possible presence of ${commaSeparatedString(possibleConditions.map((p) => p.display_name))}.`
                : ''}
            </p>
          </div>

          <div className="d-flex gap-50px mt-4">
            <div style={{ flex: 1 }} className="pb-5">
              <AiIndications setDidEdit={setDidEdit} view={IndicationsView.AiAssessment} />
            </div>

            <div style={{ flex: 2 }} className="position-relative">
              <div style={{ maxHeight: 'calc(100vh - 150px)' }} className="d-flex flex-column gap-20px overflow-scroll pb-5">
                {unhealthyImageBlocks.map((p) => p[0])}
                {healthyImageBlock}
              </div>
            </div>
          </div>
        </ModalBody>

        {radimalCase.consultations.length === 0 && (
          <div
            style={{ boxShadow: '0px -2px 10px -5px #666666', borderRadius: '0px 0px 10px 10px', position: 'sticky', bottom: 0 }}
            className="w-100 bg--secondary2 flex-center flex-column py-4"
          >
            <p className="mb-2 text-s">* Request a consultation from a board-certified specialist for more information</p>
            <Button className="mr-3 min-width-150px" color="primary" onClick={() => navigate(`/request-consult?i=${radimalCase.id}`)}>
              Request
            </Button>
          </div>
        )}
      </Modal>
    </div>
  )
}
