import React from 'react'
import { Badge } from 'reactstrap'
import { useSelector } from 'react-redux'

import ImageZoom from '../common/ImageZoom'
import { ConsultationsState, consultationsSelector } from '../../hasura/slices/consultations'

// @ts-ignore
import maximizeIcon from '../../lib/images/maximize.svg'

interface Props {
  title: string
  displayHeatmap: boolean
  zoom: any
  images: InitialAssesmentImage[]
  healthy?: boolean
  highlight?: boolean
  description?: string | null
}

export interface InitialAssesmentImage {
  heatmapUrl?: string
  name: string
  probability: number
  url: string | null
  view: string | null
  index: number
  imageId: number
}

export default (props: Props) => {
  const [isHoveringIdx, setIsHoveringIdx] = React.useState<number | null>(null)

  const { presignedCaseImageUrls }: ConsultationsState = useSelector(consultationsSelector)

  const heroImage = props.images[0]
  const otherImages = props.images.slice(1)

  const imageComponent = (image: InitialAssesmentImage, idx: number, isHero = false) => {
    const src = presignedCaseImageUrls.find((p) =>
      p.includes((props.displayHeatmap && !props.healthy ? image.heatmapUrl : image.url) || '')
    )
    const originalSrc = presignedCaseImageUrls.find((p) => p.includes(image.url || ''))
    if (!originalSrc || !src) return null

    const isHovering = isHoveringIdx === idx

    const px = isHero ? 300 : 100

    return (
      <div
        style={isHero ? { height: `${px}px`, width: `${px}px` } : { height: `${px}px`, width: `${px}px` }}
        key={idx}
        className="position-relative flex-center bg-dark border-radius-10px"
        onMouseEnter={() => setIsHoveringIdx(idx)}
        onMouseLeave={() => setIsHoveringIdx(null)}
      >
        <ImageZoom originalSrc={originalSrc} px={px} src={src} alt={image.name} zoom={props.zoom} />

        <img
          style={isHovering ? { opacity: 0.85 } : { opacity: 0 }}
          className={`position-absolute pe-none pointer ${isHero ? 'icon-l' : 'icon-m'} m-2 right-0 bottom-0 transition-f`}
          src={maximizeIcon}
        />
      </div>
    )
  }

  return (
    <div>
      <div
        className={`d-flex gap-15px max-width-700px p-3 border-radius-10px ${
          props.highlight ? 'bg--primary2' : 'border border--gray1'
        }`}
      >
        {imageComponent(heroImage, 0, true)}

        <div className="d-flex flex-column justify-content-between">
          <div>
            <div className="d-flex align-items-center mb-2">
              <p className="text-l bold mb-0 mr-2">{props.title}</p>
              {props.highlight && (
                <Badge className="mb-1" color="primary">
                  Most Likely
                </Badge>
              )}
            </div>

            <p className="text-s m-0">{props.description}</p>
          </div>

          <div className="d-flex flex-wrap gap-15px">{otherImages.map((o, idx) => imageComponent(o, idx + 1))}</div>
        </div>
      </div>
    </div>
  )
}
