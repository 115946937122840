import React from 'react'
import { CustomInput } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import { UsersState, usersSelector } from '../../hasura/slices/users'
import { updateEnterpriseDefaultOverflowAction } from '../../hasura/slices/enterprises'

export default function OverflowSwitch() {
  const dispatch = useDispatch()

  const { accessToken, user }: UsersState = useSelector(usersSelector)

  const handleUpdateDefaultOverflow = () => {
    if (!user) return

    const { id, default_overflow } = user.organization.enterprise
    dispatch(updateEnterpriseDefaultOverflowAction(accessToken, user.id, id, !default_overflow))
  }

  return (
    <div className="d-flex pointer" onClick={handleUpdateDefaultOverflow}>
      <p className="text-m mb-0 mr-1">Overflow new cases</p>

      <CustomInput
        id="overflow"
        checked={user?.organization.enterprise.default_overflow}
        className="m-0 custom-switch-light"
        role="button"
        type="switch"
      />
    </div>
  )
}
